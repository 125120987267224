
import LeadService from "../../Services/LeadService";
import ContactoAgregarModal from "./components/ContactoAgregarModal.vue";
import FECHAS from "@/Constantes/Fecha";
export default {
  name: "lead-gestor",
  components: {
    ContactoAgregarModal,
  },
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Contactos" }],
      statusOpciones: [
        this.constantesStatusCadena.lead,
        this.constantesStatusCadena.cliente,
        this.constantesStatusCadena.descartado,
      ],
      registroOpciones: FECHAS.FECHAS_FILTRO_REGISTRO,
      filtros: {
        busqueda: "",
        statusLead: [],
        fechaInicio: "",
      },
      leads: [],
      columnas: [
        {
          name: "folio",
          header: "#Contacto",
          align: "center",
          field: "folio_lead",
          minWidth: 80,
          width: 115,
          maxWidth: 115,
          templateName: "folio",
          routeName: "leadsDetalle",
          rutaId: "lead_id",
          type: "string",
        },
        {
          name: "nombre",
          header: "Nombre",
          align: "left",
          field: "nombre_completo",
          minWidth: 140,
          width: 300,
        },
        {
          name: "telefono",
          header: "Teléfono",
          align: "left",
          field: "telefono",
          minWidth: 110,
          width: 130,
          formatear: (val) => this.formatearTelefono(val, 1),
        },
        {
          name: "email",
          header: "Email",
          align: "left",
          field: "email",
          minWidth: 140,
          width: 200,
          type: "string",
        },
        {
          name: "canalCaptacion",
          header: "Canal captación",
          align: "left",
          field: "canal_captacion_nombre",
          minWidth: 120,
          width: 140,
          type: "string",
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          // field: "status",
          minWidth: 130,
          width: 150,
          templateName: "statusCadena",
          statusName: "status_lead",
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 140,
          width: 140,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Agente asignado",
          align: "left",
          field: "agente_asignado",
          minWidth: 130,
          width: 170,
        },
      ],
      accionLimpiar: false,
      modalAgregar: false,
      ladaIconoDefault: "",
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerLeads(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        statusLead: this.obtenerArrayParametrosQuery(query.sl), // El segundo parametro es para arrays con valores numericos
        fechaInicio: query.fi || "",
        ordenar: "registro_fecha_desc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        sl: this.filtros.statusLead,
        fi: this.filtros.fechaInicio,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerLeads(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await LeadService.listarGestor(this.filtros)
        .then((resp) => {
          let leads = resp;
          this.leads = leads.map((lead) => {
            return {
              ...lead,
              registro_fecha: new Date(lead.registro_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async abrirModalAgregar() {
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.modalAgregar = false;
    },
    abrirModalFiltros() {
      console.log("filtros");
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        statusLead: [],
        fechaInicio: "",
        ordenar: "registro_fecha_desc",
      };
      this.obtenerLeads();
      this.accionLimpiar = true;
    },
    async manejarExitoAlta(respuesta) {
      this.cerrarModalAgregar();
      await this.obtenerLeads();
      this.mostrarAlerta(respuesta.mensaje, "exito");
    },
  },
};
