
import LeadService from "../../../Services/LeadService";
import PaisService from "../../../Services/PaisService";
import MenuTelefonoLada from "./MenuTelefonoLada.vue";

export default {
  name: "contacto-agregar-modal",
  components: {
    MenuTelefonoLada,
  },
  props: {
    modelo: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      agregarLeadObj: {
        nombre: "",
        apellidos: "",
        lada: "",
        ladaIcono: "",
        telefono: "",
        email: "",
        direccion1: "",
        direccion2: "",
        codigoPostal: "",
        ciudad: "",
        paisId: "",
        estadoId: "",
        canalCaptacionId: "",
        plataformaId: "",
        campaniaId: "",
        nombreComercial: "",
        sitioWeb: "",
      },
      telefonosLead: [],
      paisRegionIdDefault: localStorage.getItem("paisRegionId"),
      canalCaptacionOpciones: [],
      plataformaOpciones: [],
      paisOpciones: [],
      campaniaOpciones: [],
      estadoOpciones: [],
      modalAdvertenciaTelefonoDuplicado: false,
      telefonoDuplicadoObj: {},
      ladaDefault: "",
      ladaIconoDefault: "",
      ladas: this.constantesLadas.Ladas,
    };
  },
  computed: {
    crearNombreCompleto() {
      return this.agregarLeadObj.nombre + " " + this.agregarLeadObj.apellidos;
    },
  },
  mounted() {
    // Se configura lada default
    let paisRegionCodigoDefault = localStorage.getItem("paisRegionCodigo");
    if (paisRegionCodigoDefault != null) {
      let lada = this.ladas.find(
        (l) => l.alpha3Code == paisRegionCodigoDefault
      );
      this.ladaDefault = "+" + lada.callingCodes[0];
      this.ladaIconoDefault = lada.flag;
    }
  },
  watch: {
    modelo(val) {
      if (val) this.cargaInicial();
    },
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      // Se obtiene concentrado de opciones
      if (
        _.isEmpty(this.plataformaOpciones) ||
        _.isEmpty(this.canalCaptacionOpciones) ||
        _.isEmpty(this.paisOpciones)
      )
        await this.obtenerConcentradoOpciones(false);

      // Se setean datos default
      this.agregarLeadObj.paisId = Number(this.paisRegionIdDefault);
      this.agregarLeadObj.lada = this.ladaDefault;
      this.agregarLeadObj.ladaIcono = this.ladaIconoDefault;

      // Se listan estados si el cliente tiene pais default
      if (
        this.agregarLeadObj.paisId != undefined &&
        this.agregarLeadObj.paisId > 0 &&
        _.isEmpty(this.estadoOpciones)
      ) {
        await this.obtenerEstados(Number(this.paisRegionIdDefault));
      }

      this.loader(false);
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await LeadService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.canalCaptacionOpciones = resp.canales;
          this.plataformaOpciones = resp.plataformas;
          this.paisOpciones = resp.paises;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerCampanias(plataformaId, loader = true) {
      if (loader) this.loader(true);
      this.agregarLeadObj.campaniaId = "";
      let filtros = {
        plataformaId: plataformaId,
        ordenar: "titulo_asc",
        status: ["Activa"],
      };
      await LeadService.listarCampanias(filtros)
        .then((resp) => {
          this.campaniaOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerEstados(paisId = "", limpiar = false, loader = false) {
      if (loader) this.loader(true);
      if (limpiar) this.agregarLeadObj.estadoId = "";
      let filtros = { paisId };
      await PaisService.listarEstados(filtros)
        .then((resp) => {
          this.estadoOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async agregarLead(loader = true) {
      if (loader) this.loader(true);
      let telefonos = this.telefonosLead;
      if (this.agregarLeadObj.telefono != "") {
        telefonos = telefonos.concat([
          {
            numero: this.agregarLeadObj.telefono,
            lada: this.agregarLeadObj.lada,
            default: true,
          },
        ]);
      }
      this.agregarLeadObj.telefonos = JSON.stringify(telefonos);
      this.agregarLeadObj.tipoInsert = "sistema";
      await LeadService.agregarLead(this.agregarLeadObj)
        .then(async (resp) => {
          if (resp.datos.status == 300) {
            this.telefonoDuplicadoObj = resp.datos.lead;
            this.modalAdvertenciaTelefonoDuplicado = true;
            if (loader) this.loader(false);
          } else {
            if (loader) this.loader(false);
            this.$emit("exito", resp);
          }
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        })
        .then(() => {});
    },
    cerrarModalAgregar() {
      this.agregarLeadObj = {
        nombre: "",
        apellidos: "",
        lada: "",
        ladaIcono: "",
        telefono: "",
        email: "",
        direccion1: "",
        direccion2: "",
        codigoPostal: "",
        ciudad: "",
        paisId: "",
        estadoId: "",
        canalCaptacionId: "",
        plataformaId: "",
        campaniaId: "",
        nombreComercial: "",
        sitioWeb: "",
      };
      this.telefonosLead = [];
      this.campaniaOpciones = [];
      this.$emit("cerrar-modal-agregar");
    },
    agregarTelefono() {
      let telefono = {
        numero: "",
        lada: this.ladaDefault,
        ladaIcono: this.ladaIconoDefault,
      };
      this.telefonosLead.push(telefono);
    },
    eliminarTelefono(index) {
      this.telefonosLead.splice(index, 1);
    },
    validarTelefonoPrincipal(val) {
      if (_.isEmpty(val) && _.isEmpty(this.telefonosLead)) return true;
      else if (_.isEmpty(val) && !_.isEmpty(this.telefonosLead))
        return "Télefono requerido";

      if (val.length != 10) return "Teléfono ínvalido";

      return true;
    },
    validarTelefonosDuplicados(val) {
      if (val == this.agregarLeadObj.telefono) return false;
      let cantidadTelefonos = 0;
      this.telefonosLead.forEach((tel) => {
        if (tel.numero == val) cantidadTelefonos++;
      });

      return cantidadTelefonos > 1 ? false : true;
    },
    seleccionarLada(lada, principal, telefonoIndex = null) {
      if (principal == 1) {
        this.agregarLeadObj.ladaIcono = lada.flag;
        this.agregarLeadObj.lada = "+" + lada.callingCodes[0];
      } else {
        this.telefonosLead[telefonoIndex].ladaIcono = lada.flag;
        this.telefonosLead[telefonoIndex].lada = "+" + lada.callingCodes[0];
      }
    },
    cerrarModalAdvertenciaTelefonoDuplicado() {
      this.telefonoDuplicadoObj = {};
      this.modalAdvertenciaTelefonoDuplicado = false;
    },
  },
};
